import React from 'react';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));
const IconArrowCircle = dynamic(() => import('#app/UI/atoms/icons/arrowCircle'));

const CardNews = ({
 title, description, srcImg, url 
}) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <div className={styles.container}>
        <div className={styles.containerImg}>
          <img className={styles.image} src={srcImg} alt={title} />
          {/* src="/niubiz/pci.png" */}
        </div>
        <div className={styles.details}>
          <div className={styles.text}>
            <Typography className={styles.title} tag="p">
              {title}
            </Typography>
            <Typography className={styles.description} tag="p">
              {description}
            </Typography>
          </div>
          <div className={styles.button}>
            <Typography className={styles.moreDetails} tag="p">
              Leer artículo
            </Typography>
            <IconArrowCircle fill="#BFE1FC" />
          </div>
        </div>
      </div>
    </a>
  );
};

export default CardNews;
